import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Winners from './Winners'
// import {styles} from 'ansi-colors'

import styles from './winners.module.css'

export default function AwardWinners () {
  // Set State
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [locations, setLocations] = useState({})

  useEffect(() => {
    fetchLocations()
  }, [])

  // Collect the Dynamic content
  const fetchLocations = () => {
    setLoading(true)
    axios
      .get(`/awardWinners.json`)
      .then(data => {
        // ({data})
        // Update date

        if (data.data.data.allSanityAwardWinner) {
          setError(false)
        } else {
          setError(true)
        }
        setLocations({
          data: data.data
        })
      })
      .catch(error => {
        console.log({error})
        setError(true)
        setLoading(false)
      })
  }
  // console.log({locations})
  return (
    <div>

      {error === true ? (
        <p>There has been an error, please try refreshing the page.</p>
      ) : loading && !locations.data ? (
        <div className={styles.loaderWrapper}>
          <span className={styles.loader} />
        </div>
      ) : (
        <>
          <Winners data={locations.data.data.allSanityAwardWinner.edges} awards={locations.data.data.allSanityAwardChamp.edges} />
        </>
      )}
    </div>
  )
}
