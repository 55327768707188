import React from 'react'
// import Image from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import AwardWinners from '../components/AwardWinners'

import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

export default class Index extends React.Component {
  render () {
    return (

      <StaticQuery
        query={graphql`
            query sanityRecognitionPage {
              sanityPage(slug: { current: { eq:"recognition"} }){
                title
                _rawBody
                mainImage {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
        render={data => (
          <>
            {data.sanityPage.seoSettings && data.sanityPage.seoSettings.title && data.sanityPage.seoSettings.description && (<SEO title={data.sanityPage.seoSettings.title} description={data.sanityPage.seoSettings.description} />)}

            <Layout Layout mainImage={data.sanityPage.mainImage}>

              <article className={styles.root} >

                <Container>
                  <div className={styles.pageTitleWrapper}>
                    <h1 className={styles.pageTitle}>{data.sanityPage.title}</h1>
                  </div>
                  <div>
                    <div className={styles.mainContent}>
                      <div style={{paddingRight: '30px', width: '100%'}}>
                        {data.sanityPage._rawBody && <BlockContent blocks={data.sanityPage._rawBody} />}
                        <AwardWinners />
                      </div>

                    </div>
                  </div>
                </Container>

              </article>

            </Layout>
          </>
        )}
      />
    )
  }
}
